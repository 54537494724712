@import "../../../variable.scss";

// .assesment-container {
//   max-width: 608px;
//   margin: auto;
//   .main-info {
//     display: flex;
//     align-items: center;
//     grid-gap: 50px !important;
//     margin-top: 32px;
//     margin-bottom: 32px;
//     font-size: $f-18;
//     font-weight: $fw-400;
//     h3 {
//       text-align: center;
//     }
//     .points {
//       border: 2px solid black;
//       border-radius: 10px;
//       margin-bottom: 32px;
//       width: auto;
//       height: auto;
//       margin: auto;
//       padding: 10px;
//       p {
//         font-weight: 200;
//         margin: 15px;
//       }
//     }
//     p {
//       line-height: 30px;
//       margin: 0;
//       text-align: left;
//     }
//     img {
//       width: 73px;
//       height: 83px;
//     }
//     @media (min-width: 768.98px) {
//       align-items: flex-start;
//       grid-gap: 20px !important;
//       text-align: left !important;
//     }
//   }
//   .time-info {
//     display: flex;
//     align-items: center;
//     margin-bottom: 32px;
//     justify-content: center;
//     grid-gap: 8px;
//     font-size: $f-16;
//     font-weight: $fw-400;
//   }
// }
// .submit-text {
//   max-width: 460px;
//   margin: 0 auto 32px;
// }

.assesment-container {
}
.userWrapper {
  h3 {
    text-align: center;
    @media (max-width: 767px) {
      text-align: left !important;
    }
  }
  .main-info {
    flex-direction: column ;
    p {
      color: #575555;
    }
  }
  .points {
    border: 2px solid #c3c3c3;
    border-radius: 10px;
    margin-bottom: 32px;
    width: auto;
    height: auto;
    color: #3a3939;
    margin-bottom: 32px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      align-items: left !important;
      justify-content: left !important;
      text-align: left !important;
      font-weight: 500;
      display: flex;
      margin-bottom: 0;
      align-items: flex-start;
      gap: 10px;
      img {
        margin-top: 3px;
      }
    }
  }
}
