$primaryColor: #0F1D2B;
$blackColor: #222222;
$highlight: #F8FAFB;
$highlightBorder: #C4C4C4;
$linkColor: #D71440;
$TabColor: #999999;
$activeGreen: #56A741;
$white: #ffffff;
$menuActiveLink: #397EAA;



$f-12: 12px;
$f-14: 14px;
$f-16: 16px;
$f-18: 18px;
$f-20: 20px;
$f-28: 28px;
$f-36: 36px;


$fw-400: 400;
$fw-500:500;
$fw-600: 600;
$fw-700: 700;