@import "../../../variable.scss";

.title {
    text-align: center!important;
}
.assesment-container{
    max-width: 608px;
    margin: auto;
    .main-info{
        display: flex;
        align-items: center;
        flex-direction: row!important;
        grid-gap: 50px!important;
        margin-top: 32px;
        margin-bottom: 32px;
        font-size: $f-18;
        font-weight: $fw-400;
        p{
            line-height: 30px;
            margin: 0;
            text-align: left;
        }
        img{
            width: 73px;
            height: 83px;
        }
        @media (min-width: 768.98px){
            align-items: flex-start;
            grid-gap: 20px!important;
        }
    }
    .time-info{
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: center;
        grid-gap: 8px;
        font-size: $f-16;
        font-weight: $fw-400;
    }
}
.submit-text{
    max-width: 460px;
    margin: 0 auto 32px;
}