.navbar-expand-xxl .navbar-toggler{
    display: block!important;
    padding: 0;
}
.navbar-expand-xxl .navbar-collapse.collapse{
    display: none!important;
}
.navbar-expand-xxl .navbar-collapse.collapse.show{
    display: flex!important;
}
.navbar-expand-xxl .offcanvas{
    display: none;
}
.offcanvas-header{
    justify-content: flex-end!important;
    
    a{
        font-size: 16px;
        font-weight: 400;
        grid-gap: 11px;
        align-items: center;
        display: flex;
    }
    .btn-close{
        opacity: 1;
    }
}
.check-row{
    display: flex;
    align-items: flex-start;
    grid-gap: 16px;
    max-width: 400px;
    margin: auto;
    margin-bottom: 24px;
    .check-label{
        margin-top: 4px;
        font-size: 16px;
        line-height: 28px;
        a{
            color: #D71440;
            text-decoration: none;
        }
    }
    .custom-checkbox{
        input{
            position: absolute;
            left: -9999px;
        }
        label{
            border: 2px solid #999999;
            border-radius: 4px;
            width: 32px;
            height: 32px;
            cursor: pointer;
            position: relative;
            &::before{
                content: '';
                background: url(../img/check-tick.svg) no-repeat;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 6px;
                left: 6px;
                opacity: 0;
            }
        }
        input:checked + label{
            border-color: #56A741;
            &::before{
                opacity: 1;
            }
        }
    }
}
