@import "../../../variable.scss";
.userWrapper {
    .form-check {
        .form-check-input {
            margin-left: 0;
        }
        label {
            width: auto;
            margin-left: 28px;
        }
    }
    h3 {
        font-weight: $fw-600;
        font-size: 28px;
        line-height: 40px;
        color: $primaryColor;
        margin-bottom: 15px;
        text-align: left;
    }
    a{
        text-decoration: none;
        color: #D71440;
      }
      .form-control:has(> .form-check-input:checked) {
        background: #56A741;
        .form-check-label{
            color: #fff;
        }
    }
    .form-check-input:checked {
        background-color: #fff;
        border-color: #fff;
    }
    .form-check-input:checked::after {
        content: '';
        border: 2px solid #56a741;
        width: 11px;
        height: 5px;
        display: block;
        transform: rotate(-45deg);
        border-top: 0;
        border-right: 0;
        margin-top: 4px;
        margin-left: 2px;
    }
}

@media (min-width:767px) {
    .userWrapper{
         h3{
        font-size: 36px;
    }
    }
}
hr{
    margin: 12px 0 24px!important;
}
   