@font-face {
  font-family: "headLine";
  src: url("../../assets/fonts/PostOfficeHeadline_W_SBd.woff2") format("woff2");
  /* Add other font properties like font-weight and font-style here if needed */
}

.forgotPassword {
  max-width: 100%;
  margin: auto;
  text-align: right;
  font-size: 14px;
  margin-bottom: 5px;
  transform: translateY(-14px);
  color: #3484c9;
  @media (min-width: 768.98px) {
    max-width: 400px;
  }
}
.password-main-div {
  position: relative;
  cursor: pointer;
  .eye-icon {
    position: absolute;
    top: 2px;
    right: 17px;
    font-size: 30px;
    svg {
      width: 20px;
    }
  }
}

.headLine {
  font-family: "headLine";
}
