@import "../../variable.scss";

.site-footer {
    position: relative;
    footer {
        align-items: center;
        justify-content: space-between;
        padding: 56px 24px;
        background: #D71440;
        color: #fff;
        font-size: $f-14;
        font-weight: $fw-400;
        display: none;
        @media (min-width: 768.98px) {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 20px;
        }
    }
    .nav {
        align-items: center;
        margin-left: auto;
        .nav-item:not(:last-child) {
            a {
                padding: 0;
                color: #fff;
                padding-right: 6px;
                border-right: 2px solid;
                margin-right: 6px;
                line-height: 13px;
            }
        }
        .nav-item:last-child {
            a {
                border: 4px solid #3484c9;
                border-radius: 100px;
                background: #fff;
                margin-left: 22px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $f-16;
                font-weight: $fw-500;
                margin-left: 0;
                margin-top: 1rem;
                padding: 8px 20px;
                @media (min-width: 768.98px) {
                    margin-left: 22px;
                    margin-top: 0;
                }
            }
        }
    }
}
.help-popup {
    position: absolute;
    bottom: 80%;
    z-index: 999999999999999;
    background: #fff;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgb(2 48 71 / 15%);
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    right: 0;
    padding: 2rem;
    h5 {
        font-weight: $fw-600;
        font-size: $f-20;
        margin-bottom: 2rem;
    }
    .form-label {
        font-size: $f-18;
        font-weight: $fw-600;
    }
    .conct-us {
        height: 56px;
        border: 4px solid #626262;
        border-radius: 30px;
        display: block;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        color: #0f1d2b;
        text-decoration: none;
        font-weight: $fw-600;
        font-size: $f-16;
    }
    .accountSub li a {
        font-size: $f-16;
    }
    .input-group-text {
        background: #fff;
        margin-right: -2px;
        border-right: 0;
        position: relative;
        z-index: 9;
    }
    .input-group>.form-control{
        box-shadow: none;
        outline: none;
    }
}
.mobile-footer {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    padding: 1rem;
    border-top: 1px solid #ddd;
    @media (min-width: 768.98px) {
        display: none;
    }
    ul.mobile-tabs {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: 0;
        justify-content: space-around;
        margin: 0;
        li {
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #333;
                gap: 4px;
                text-decoration: none;
                svg{
                    width: 22px;
                    height: 18px;
                }
                span{
                    font-size: 12px;
                    font-weight: 600;
                    color: #626262;
                }
                &.active{
                        color: $menuActiveLink;
                        svg{
                            path{
                                fill: $menuActiveLink;
                            }
                        }
                        span{
                            font-weight: $fw-600;
                            color: $menuActiveLink;
                        }
                }
            }
        }
    }
}
