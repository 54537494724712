@import "../../../../variable.scss";
.userWrapper {
    .form-check {
        .form-check-input {
            margin-left: 0;
        }
        label {
            width: auto;
            margin-left: 28px;
        }
    }
    
    a {
        text-decoration: none;
        color: #D71440;
    }
    .password-condtion {
        margin-bottom: 2rem;
        p {
            text-align: left;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 8px;
            svg{
                color: #C4C4C4;
                margin-right: 8px;
            }
            &.active{
                svg{
                    color: #56A741;
                }
            }
        }
    }
}
.password-main-div{
    position: relative;
    cursor: pointer;
    .eye-icon{
        position: absolute;
        top: 2px;
        right: 17px;
        font-size: 30px;
    }
}