@import "../../variable.scss";

.title {
    text-align: center!important;
}
.assesment-container{
    .main-info{
        grid-gap: 25px!important;
        @media (min-width: 576px){
            grid-gap: 50px!important;
        }
        img{
            height: 63px!important;
        }
    }
    .bottom-btn-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 32px;
        .red-border-btn{
            max-width: 400px;
            width: 100%;
            margin: auto;
            border: 4px solid #E63329;
            border-radius: 100px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 8px;
            background: #fff;
            font-size: $f-16;
            font-weight: $fw-500;
        }
    }
}
