@import "../../variable.scss";

.button-border{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button{
        background: #FFFFFF;
        border: 4px solid #D71440;
        border-radius: 100px;
        max-width: 400px;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        font-size: $f-16;
        font-weight: $fw-500;
        color: #D71440;
        &:hover,&:active{
            border: 4px solid #D71440!important;
            color: #D71440!important;
        }
        @media (min-width: 576px){
            font-size: $f-20;
        }
    }
}